import { useState, useEffect } from "react";
import {
  Card,
  Dropdown,
  DropdownItem,
  DateRangePicker,
  DateRangePickerValue,
  BarChart,
  Button,
  Grid,
  Col,
  Metric,
  DonutChart,
  Toggle,
  ToggleItem
} from "@tremor/react";
import './DashboardPage.css';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import { ptBR } from "date-fns/locale";
import { addDays, differenceInDays } from "date-fns";
import { addMonths } from "date-fns";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useToast } from "../lib/useToast"
import { Toaster } from "../components/Toaster"
import { CountCountryOrders, CountDailyOrders, CountSourceOrders, CountStatusOrders } from "../interfaces/MetricsInterfaces";
import { Chart } from "react-google-charts";

import {
  QrCodeIcon,
  PaperAirplaneIcon,
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

const mainStatusConfig: {
  [key: string]: { color: string; colorBall: string; label: string; icon: JSX.Element };
} = {
  collected: {
    color: "bg-purple-200",
    colorBall: "bg-purple-500",
    label: "Coletado",
    icon: <QrCodeIcon className="h-4 w-4 text-white" />,
  },
  en_route: {
    color: "bg-orange-200",
    colorBall: "bg-orange-500",
    label: "Em rota",
    icon: <PaperAirplaneIcon className="h-4 w-4 text-white" />,
  },
  delivered: {
    color: "bg-green-200",
    colorBall: "bg-green-500",
    label: "Entregue",
    icon: <CheckIcon className="h-4 w-4 text-white" />,
  },
  handling: {
    color: "bg-red-200",
    colorBall: "bg-red-500",
    label: "Em tratativa",
    icon: <ExclamationTriangleIcon className="h-4 w-4 text-white" />,
  },
};

const sourceNames: { [key: string]: string } = {
  "mazelog": "Mazelog",
  "mercado_livre": "Mercado Livre",
  "shopee": "Shopee",
};

const normalizeStatusData = (data: { status: string; count: number }[]) => {
  const normalizedData = Object.keys(mainStatusConfig).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
  }, {} as { [key: string]: number });

  data.forEach((item) => {
    if (normalizedData.hasOwnProperty(item.status)) {
      normalizedData[item.status] = item.count;
    }
  });

  return Object.entries(normalizedData).map(([status, count]) => ({
    status,
    count,
  }));
};

const normalizeSourceData = (data: { source: string; count: number }[]) => {
  const fixedSources = ["mazelog", "mercado_livre", "shopee"];
  return fixedSources.map((source) => {
    const found = data.find((item) => item.source === source);
    return {
      source,
      count: found?.count || 0,
    };
  });
};

export default function DashboardPage() {
  const [cookies] = useCookies(['mazelog-user']);
  const { toast } = useToast()
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;

  const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

  const [requested, setRequested] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangePickerValue>([
    addDays(new Date(), -30),
    new Date(),
  ]);

  const maxDate = new Date();

  const [loading, setLoading] = useState(false);

  const [countDailyOrders, setCountDailyOrders] = useState<CountDailyOrders[]>();
  const [countSourceOrders, setCountSourceOrders] = useState<CountSourceOrders[]>();
  const [countStatusOrders, setCountStatusOrders] = useState<CountStatusOrders[]>();
  const [countCountryOrders, setCountCountryOrders] = useState<CountCountryOrders[]>();
  
  const [dataCountryOrders, setDataCountryOrders] = useState([["Country", "Pedidos"], ["Brazil", 0]]);
  const [otherStatusCount, setOtherStatusCount] = useState(0); 

  const [clientSelected, setClienteSelected] = useState("");
  const [filterDateTypeSelected, setFilterDateTypeSelected] = useState("false");


  const handleSubmit = async () => {
    setLoading(true); 
    try {
      const startDate = selectedDateRange[0]?.toISOString().split('T')[0];
      const endDate = selectedDateRange[1]?.toISOString().split('T')[0];
  
      // Construindo a query para incluir id_org somente se clientSelected não estiver vazio
      const idOrgQuery = clientSelected ? `&id_org=${clientSelected}` : '';
  
      // Chamando o endpoint para pedidos diários
      const dailyOrdersURL = `${baseURL}/metrics/daily_orders?start_date=${startDate}&end_date=${endDate}${idOrgQuery}&update_date=${filterDateTypeSelected}`;
      const dailyOrdersResponse = await axios.get(dailyOrdersURL, {
        headers: {
          accept: 'application/json',
          Authorization: oauth,
        },
      });
      const dailyOrders = dailyOrdersResponse.data.results || [];
      setCountDailyOrders(dailyOrders);

      // Calculando os pedidos do Brasil
      const brazilOrderCount = dailyOrders.reduce((sum: any, order: { count: any; }) => sum + order.count, 0);

      // Atualizando o estado de countCountryOrders
      setCountCountryOrders([{ country: "Brazil", count: brazilOrderCount }]);

      // Atualizando os dados do gráfico
      setDataCountryOrders([
        ["Country", "Pedidos"],
        ["Brazil", brazilOrderCount],
      ]);
  
      // Chamando o endpoint para pedidos por fonte
      const sourceOrdersURL = `${baseURL}/metrics/order_sources?start_date=${startDate}&end_date=${endDate}${idOrgQuery}&update_date=${filterDateTypeSelected}`;
      const sourceOrdersResponse = await axios.get(sourceOrdersURL, {
        headers: {
          accept: 'application/json',
          Authorization: oauth,
        },
      });
      
      const sourceData = normalizeSourceData(sourceOrdersResponse.data.results);
      setCountSourceOrders(sourceData);
  
      // Chamando o endpoint para pedidos por status
      const statusOrdersURL = `${baseURL}/metrics/daily_orders_status?start_date=${startDate}&end_date=${endDate}${idOrgQuery}&update_date=${filterDateTypeSelected}`;
      const statusOrdersResponse = await axios.get(statusOrdersURL, {
        headers: {
          accept: 'application/json',
          Authorization: oauth,
        },
      });
      setCountStatusOrders(statusOrdersResponse.data.results);
  
    } catch (err) {
      console.error("Erro ao buscar dados:", err);
    } finally {
      setLoading(false);
    }
  }; 

  const handleDateChange = (newRange: DateRangePickerValue) => {
    if (newRange[0] && newRange[1]) {
      const diffDays = differenceInDays(newRange[1], newRange[0]);

      if (diffDays <= 90) {
        setSelectedDateRange(newRange);
      } else {
        // Ajusta automaticamente para 90 dias
        const adjustedEndDate = addDays(newRange[0], 90);
        setSelectedDateRange([newRange[0], adjustedEndDate]);
        toast({
          title: "Warning",
          description: "Selecionar um limite maximo de 90 dias",
          variant: "warning",
          duration: 3000,
        })
      }
    } else {
      setSelectedDateRange(newRange);
    }
  };

  useEffect(() => {
    if (!requested) {
      handleSubmit();
      setRequested(true);
    }
  }, [requested]);

  useEffect(() => {
    if (countStatusOrders) {
      const mainStatusKeys = Object.keys(mainStatusConfig);
      const otherCount = countStatusOrders
        .filter((item) => !mainStatusKeys.includes(item.status))
        .reduce((acc, item) => acc + item.count, 0);
  
      setOtherStatusCount(otherCount);
    }
  }, [countStatusOrders]);

  useEffect(() => {

  }, [countDailyOrders])

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <Toaster />
        <div className="p-4">
          <Grid numCols={6} className="gap-2 mb-4 bg-white p-2 rounded-lg shadow-sm">
            <Col numColSpan={2}>
            <DateRangePicker
              className="max-w-md"
              value={selectedDateRange}
              onValueChange={handleDateChange}
              locale={ptBR}
              dropdownPlaceholder="Selecionar"
              enableYearPagination={true}
              maxDate={maxDate} 
            />
            </Col>
            <Col numColSpan={1}>
              <Toggle onValueChange={(value) => setFilterDateTypeSelected(value)} value={filterDateTypeSelected} color='purple' className='h-full'>
                <ToggleItem value='false' text='Criação'>Hey</ToggleItem>
                <ToggleItem value='true' text='Atualização'>Ho</ToggleItem>
              </Toggle>
            </Col>
            <Col numColSpan={2}>
              <div></div>
            </Col>
            <Col numColSpan={1}>
              <Button className='FilterButton align-top t-0 h-full' onClick={() => handleSubmit()}>
                Filtrar
              </Button>
            </Col>
          </Grid>

          <Grid numCols={6} className="gap-4">
            <Col numColSpan={4}>
              <Card className="mb-4">
                <div className="flex justify-between">
                  <Metric className="font-bold text-lg">Contagem por Status</Metric>
                    {loading ?<div></div> : (
                    <div className="flex bg-gray-200 px-4 py-2 rounded-xl gap-4 items-center">
                      <div className={`h-6 w-6 rounded-full bg-gray-500`}></div>
                      <h2 className="font-bold text-lg">{otherStatusCount}</h2>
                      <h2 className="text-neutral-500 font-bold text-sm">Outros</h2>
                    </div>
                  )}
                </div>
                <Grid
                  numCols={Object.keys(mainStatusConfig).length}
                  className="gap-4 mt-4"
                >
                  {!countStatusOrders || loading ? (
                    <Col numColSpan={4} className="flex justify-center items-center">
                      <div className="spinner h-16 w-16"></div>
                    </Col>
                  ) : (
                    normalizeStatusData(countStatusOrders).map((status) => {
                      const config = mainStatusConfig[status.status];

                      return (
                        <Col
                          key={status.status}
                          numColSpan={1}
                          className={`${config.color} h-28 p-3 rounded-xl`}
                        >
                          <div className={`h-8 w-8 rounded-full mb-1 ${config.colorBall} flex items-center justify-center`}>
                            {config.icon}
                          </div>
                          <h2 className="font-bold text-xl pl-2">{status.count}</h2>
                          <h2 className="text-neutral-500 pl-1 mb-2 font-bold text-sm">{config.label}</h2>
                        </Col>
                      );
                    })
                  )}
                </Grid>
              </Card>

              <Card className="">
                <Metric className="font-bold text-lg">Número de pedidos diários</Metric>
                {!countDailyOrders || loading ? 
                  <div className="flex justify-center items-center">
                    <div className="spinner h-16 w-16"></div>
                  </div> :
                  <div>
                    <BarChart
                      data={countDailyOrders}
                      index="date"
                      categories={['count']}
                      colors={['purple']}
                      yAxisWidth={48} />
                  </div>
                }
              </Card>
            </Col>

            <Col numColSpan={2}>
            <Card>
            <Metric className="font-bold text-lg">Canal de Venda</Metric>
            {!countSourceOrders || loading  ? (
              <div className="flex justify-center items-center">
                <div className="spinner h-16 w-16"></div>
              </div>
            ) : (
              <div>
                <DonutChart
                  data={countSourceOrders.map((item) => ({
                    ...item,
                    source: sourceNames[item.source] || item.source, // Nome traduzido
                  }))}
                  category="count"
                  index="source"
                  colors={["purple", "yellow", "red"]}
                  valueFormatter={(value) => `${value} pedidos`}
                  showTooltip={true}
                  className="h-64 mt-4"
                />

                <div className="mt-4 space-y-2">
                  {countSourceOrders.map((item, index) => {
                    const total = countSourceOrders.reduce((acc, curr) => acc + curr.count, 0);
                    const percentage = total > 0 ? ((item.count / total) * 100).toFixed(2) : "0.00";

                    const colors = ["bg-purple-500", "bg-yellow-500", "bg-red-500"];

                    return (
                      <div key={item.source} className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className={`w-3 h-3 rounded-full mr-2 ${colors[index]}`} />
                          <span className="font-medium text-gray-700">
                            {sourceNames[item.source] || item.source} {/* Nome traduzido */}
                          </span>
                        </div>
                        <span className="text-sm text-gray-700 font-medium">{percentage}%</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Card>
          <Card className="mt-4">
            <Metric className="font-bold text-lg">Regiões Atendidas</Metric>
            { loading ? 
            <div className="flex justify-center items-center">
              <div className="spinner h-16 w-16"></div>
            </div>
            : (
            <Chart
              chartEvents={[
                {
                  eventName: "select",
                  callback: ({ chartWrapper }) => {
                    if (chartWrapper) {
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();
                      if (selection.length === 0) return;
                      const region = dataCountryOrders[selection[0].row + 1];
                      console.log("Selected : " + region);
                    }
                  },
                },
              ]}
              chartType="GeoChart"
              width="100%"
              height="100%"
              data={dataCountryOrders}
              options={{
                // region: "002", // Africa
                colorAxis: { colors: ["#A855F7"] },
                // backgroundColor: "#81d4fa",
                // datalessRegionColor: "#f8bbd0",
                // defaultColor: "#f5f5f5",
              }}
            />
          )}
          </Card>
            </Col>
          </Grid>
        </div>
      </div>
    </main>
  );
}
