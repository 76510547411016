import React, { useEffect, useState } from 'react';
import CreateOrdersPage from './pages/CreateOrdersPage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import StickerPage from './pages/StickerPage';
import SearchPage from './pages/SearchPage';
import OrderDetailPage from './pages/OrderDetailPage';
import CorreiosDeclarationPage from './pages/CorreiosDeclarationPage';
import OccurrenceListPage from './pages/Occurrences/OccurrenceListPage';
import OccurrenceChatPage from './pages/Occurrences/OccurrenceChatPage';
import OrderBatchPage from './pages/OrderBatchPage';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Drivers } from './interfaces/UserInterfaces';
import { FullOrder } from './interfaces/OrderInterfaces';
import { Toast, ToastProvider } from "./components/Toast"
import { Toaster } from "./components/Toaster"
import { useToast } from "./lib/useToast"

export default function App() {
  const { toast } = useToast()
  const [cookies] = useCookies(['mazelog-user']);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [drivers, setDrivers] = useState<Drivers>({});
  const [selectedTransporter, setSelectedTransporter] = useState<string>('mazelog');
  const [manualBaseOrder, setManualBaseOrder] = useState<undefined|FullOrder>();
  const [showCard, setShowCard] = useState("0");
  const [reverseLogistic, setReverseLogistic] = useState<boolean>(false);

  const getDrivers = () => {
    const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL
    axios.get(`${baseURL}/users/transporters`,
    {headers: {"Authorization": "Bearer " + cookies["mazelog-user"]?.token_data?.access_token}})
    .then((res) => {
      setDrivers(res.data.transporters)
    })
    .catch(err =>
      console.log(err)
    )
  }

  useEffect(()=>{
    setIsLoggedIn(['CORP','CLIENT','TRANSPORTER'].includes(cookies["mazelog-user"]?.organization_data?.profile));
    getDrivers();
  },[cookies])

  return (
    <div  className="App">
      {isLoggedIn ? 
      <Router basename={process.env.PUBLIC_URL}>
        <ToastProvider>
          <Routes>
            <Route
              path="/orders"
              element={
                <StickerPage
                  orderIds={selectedOrders}
                  setReverseLogistic={setReverseLogistic}
                />
              }
            />

            <Route
              path="/orders/declaration/:ids"
              element={
                <CorreiosDeclarationPage
                  orderIds={selectedOrders}
                />
              }
            />

            <Route
              path="/batches"
              element={
                <OrderBatchPage setSelectedOrders={setSelectedOrders}/>
              }
            />

            <Route
              path="/create"
              element={
                <CreateOrdersPage
                  setCreatedOrders={setSelectedOrders}
                  setSelectedTransporter={setSelectedTransporter}
                  selectedTransporter={selectedTransporter}
                  manualBaseOrder={manualBaseOrder}
                  showCard={showCard}
                  setShowCard={setShowCard}
                  reverseLogistic={reverseLogistic}
                  setReverseLogistic={setReverseLogistic}
                />}
            />

            <Route path="/dash" element={<DashboardPage />} />

            <Route
              path="/"
              element={
                <SearchPage
                  drivers={drivers}
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                />
              }
            />

            <Route path="/order/:id" element={
              <OrderDetailPage
                drivers={drivers}
                setShowCard={setShowCard}
                setManualBaseOrder={setManualBaseOrder}
                setReverseLogistic={setReverseLogistic}
              />}
            />

            <Route path="/occurrence" element={<OccurrenceListPage />} />

            <Route path='/occurrence/:id' element={<OccurrenceChatPage />} />

            {/*<Route path="*" element={<NotFound />} />*/}
          </Routes>
        </ToastProvider>
      </Router>
      :
      <LoginPage/>
      }
    </div>
  );
}
